<template>
    <div class="main">
        <div class="header">
            网剧推广
        </div>
        <div style="margin-top: 40px"></div>

        <div >
            <el-carousel :interval="3000" arrow="always" height="200px" style="width: 100%;overflow: hidden;">
                <el-carousel-item v-for="iter in carouselList" :key="iter.id">
                    <el-image :src=iter style="object-fit: cover; width: 100%; height: 100%;"></el-image>
                </el-carousel-item>
            </el-carousel>
        </div>
        <!-- 内容1 -->
        <div style="margin-top:20px">
            <div style="margin-left: 10px;display: flex; align-items: center; justify-content: flex-start;">
                <div style="width: 4px;height: 20px;border-radius: 10px;margin-right: 6px;background-color: #2979ff;"></div>
                <span style="color: #000000;font-size: 16px;font-weight:normal;font-family:微软雅黑;">热门网剧</span>
            </div>
            <!-- 循环显示 v-for -->
            <div>
                <el-row>
                    <!-- 循环显示 v-for -->
                    <el-col :span="12" v-for="(item, index) in list" :key="index" style="padding:10px ">
                        <el-card :body-style="{ padding: '0px' }">
                            <div style="cursor: pointer;height:175px;" @click="toDetails(item)">
                                <div >
                                    <div style="overflow: hidden;">
                                        <img style="object-fit: cover; width: 100%; height: 120px;" :src="item.img">
                                    </div>
                                    <div class="plan_list_text">
                                        {{item.title}}
                                    </div>
                                    <div style="display: flex; align-items: center; justify-content: space-between;padding:2px 5px;">
                                        <div style="color: #999;">
                                            <div>
                                                <img v-if="!item.imgflag" :src="require('@/assets/热度off.png')"
                                                     style="object-fit: cover; width: 15px; height: 15px;">
                                                <img v-else :src="require('@/assets/热度.png')"
                                                     style="object-fit: cover; width: 15px; height: 15px;">
                                                {{item.heat}}
                                            </div>
                                        </div>
                                        <div>
                                            <el-tag size="mini" style="font-size: 12px">
                                                剧情简介
                                            </el-tag>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
            </div>
        </div>

        <el-divider style=""></el-divider>


    </div>
</template>

<script>
    export default {
        name: "Index",
        data() {
            return {
                list: [],
                item: {},
                drawer: false,
                isZooming: false, // 控制动画层的显示
                animationList: [], // 用于存储所有动画的状态
                carouselList: [
                    require('@/assets/bg/111.jpg'),
                    require('@/assets/bg/222.jpg'),
                    require('@/assets/bg/333.jpg'),
                ],
            };
        },
        created() {
            this.load();
        },
        methods: {
            //列表展示
            load() {
                var url = "/app/teleplay/queryList";
                let membertoken = localStorage.getItem("membertoken")
                if (membertoken) {
                    url = "/app/teleplay/queryListAuth";
                }
                this.request.get(url).then(res => {
                    if (res.data) {
                        res.data.forEach(item => {
                            // item.imgflag = false;
                            item.animation = Math.random() * 5 + 3
                        })
                    }
                    this.list = res.data;
                });
            },
            toDetails(item){
                this.$router.push("/details?id="+item.id)
            }
        }
    };
</script>

<style scoped>
    * {
        font-family: 微软雅黑;
        font-size: 14px;
    }

    .header {
        position: fixed;
        top: 0;
        z-index: 1000; /* 确保标题栏位于其他内容之上 */
        background-color: #000;
        color: #fff;
        width: 100%;
        height: 40px;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .plan_list_text {
        font-size: 14px;
        padding: 2px 5px;
        overflow: hidden;
        word-break: break-all; /* break-all(允许在单词内换行。) */
        text-overflow: ellipsis; /* 超出部分省略号 */
        display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
        -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
        -webkit-line-clamp: 1; /** 显示的行数 **/
    }


</style>

